<template>
  <div class="text-left ChoosePlanNew">
    <div class="row Choose-plan-Main">
      <div class="col-12 d-flex justify-content-center mb-5">
        <div class="btn-group" v-if="selectedBtn == 'yearly'">
          <div class="d-flex align-items-center">
            <button
              class="btn px-4 py-2"
              :class="selectedBtn == 'yearly' ? 'selectedBtn' : null"
            >
              Yearly
            </button>
            <p class="m-0 px-4 py-2" @click="changeSelectedBtn('monthly')">
              Monthly
            </p>
          </div>
        </div>
        <div class="btn-group" v-else>
          <div class="d-flex align-items-center">
            <p
              class="m-0 px-4 py-2 yearly"
              @click="changeSelectedBtn('yearly')"
            >
              Yearly
            </p>
            <button
              class="btn px-4 py-2"
              :class="selectedBtn == 'monthly' ? 'selectedBtn' : null"
            >
              Monthly
            </button>
          </div>
        </div>
      </div>

      <div class="col-12">
        <!-- Yearly Plan start-->
        <div class="row" v-if="selectedBtn == 'yearly'">
          <div
            class="col-12 col-sm-10 mx-auto mx-xl-0 col-md-12 col-lg-5 col-xl-3 mb-3 h-100"
            v-for="(plan, index) in yearlyPlans"
            :key="index"
          >
            <div
              class="card PricingCard w-100 p-3 h-100"
              style="min-height: 400px;cursor:pointer;"
              @click="openPlan(plan)"
              :style="plan.name == 'Pro' ? 'border: 1px solid #0FD452' : null"
            >
              <div class="d-flex flex-column flex-grow-1">
                <div class="PriceTopSection p-3">
                  <div class="priceSection">
                    <div class="d-flex align-items-center mb-2">
                      <p class="price m-0" v-if="plan.name == 'Free'">
                        ${{ plan.payment_amount }}
                      </p>
                      <p class="price m-0" v-else>
                        ${{ plan.payment_amount / 12 }}
                      </p>
                      <span v-if="plan.name == 'Free'" class="subText m-0 mt-3"
                        >/free forever</span
                      >
                      <span v-else class="subText m-0 mt-3">/Monthly</span>
                    </div>

                    <div
                      class="title d-flex flex-column justify-content-between align-items-start"
                    >
                      <p class="m-0">{{ plan.name }}  <span
                        v-if="plan.name == 'Pro'"
                        class="mb-2  most-popular "
                        >Most popular</span
                      ></p>
                     
                    </div>

                    <div class="priceText" style="display:none;">
                      <span>{{ plan.description }}</span>
                    </div>
                  </div>
                </div>

                 <div class="buttonSection">
                <button
                  :disabled="isBtnDisabled(plan)"
                  class="btn w-100 px-3 py-2 StartBtn"
                  :style="getBtnStyles(plan)"
                  @click="openPlan(plan)"
                >
                  <span class="startBtn">{{
                    user.planId == plan.id ? "Current Plan" : "Start Now"
                  }}</span>
                </button>
              </div>
                
                <div class="midSection p-3">
                  <div class="midFeatures mb-2">
                    <!-- Engagements -->
                    <div class="">
                      <div>
                        <p class="m-0 mb-2">
                          <i
                            class="fa fa-check-circle mr-2"
                            :style="getTextColor(plan)"
                            aria-hidden="true"
                          ></i>
                          <span>{{ plan.plan_quota.engagements }} </span>
                          Engagements monthly
                        </p>
                      </div>
                    </div>
                    <!-- Engagements End-->

                    <div class="">
                      <p class="m-0 mb-2">
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        <span class="" v-if="plan.plan_quota.quizzes <= 1"
                          >{{ plan.plan_quota.quizzes }} Quiz</span
                        >
                        <span class="" v-else
                          >{{ plan.plan_quota.quizzes }} Quizzes</span
                        >
                      </p>
                    </div>
                    <!-- Quiz End-->
                    <div class="">
                      <p
                        class="m-0 mb-2"
                        v-if="plan.plan_quota.questions !== 0"
                      >
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        Up to
                        <span class=""> {{ plan.plan_quota.questions }} </span
                        >Questions
                      </p>
                      <p
                        v-else
                        class="font-weight-bold text-uppercase m-0 mb-2"
                      >
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        Unlimited
                      </p>
                    </div>
                    <!-- Question End-->
                    <div class="">
                      <p class="m-0 mb-2" v-if="plan.plan_quota.products !== 0">
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        Up to
                        <span>{{ plan.plan_quota.products }} </span>Products
                      </p>
                      <p
                        v-else
                        class="font-weight-bold text-uppercase m-0 mb-2"
                      >
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        Unlimited
                      </p>
                    </div>
                    <!-- Products End-->
                  </div>

                  <div class="midCheckFeatures">
                    <div v-for="(check, index) in planFeature" :key="index">
                      <p class="m-0 mb-2">
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        {{ check.text }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="endSection px-3 pb-3">
                  <!-- <div class="d-flex align-items-center" v-for="(service , index) in plan.services" :key="index">
                               <p class="text-uppercase mr-2" style="font-weight:800;" :style="getTextColor(plan)">{{service.title}}</p>
                           </div> -->
                  <!-- <div class="d-flex align-items-center">
                               <p class="text-uppercase mr-2" :style="`font-weight:900;color:${getTextColor(plan)}`">{{plan.AnaliticsText}}</p>
                               <p>{{plan.AnaliticsTextValue}}</p>
                           </div> -->
                </div>
              </div>
             
              <!-- :class="`${plan.name}-btn`" -->
              
            </div>
          </div>
        </div>
        <!-- Yearly Plan end-->

        <!-- Monthly Plan starts -->
        <div class="row" v-else>
          <div
            class="col-12 col-sm-10 mx-auto mx-xl-0 col-md-12 col-lg-5 col-xl-3 mb-3 h-100"
            v-for="(plan, index) in monthlyPlans"
            :key="index"
            style="height: 100%"
          >
            <div
              class="card PricingCard w-100 p-3 h-100"
              style="min-height: 400px;cursor:pointer;"
              @click="openPlan(plan)"
              :style="plan.name == 'Pro' ? 'border: 1px solid #0FD452' : null"
            >
              <div class="d-flex flex-column flex-grow-1">
                <div class="PriceTopSection p-3">
                  <div class="priceSection">
                    <div class="d-flex align-items-center mb-2">
                      <p class="price m-0">${{ plan.payment_amount }}</p>
                      <span v-if="plan.name == 'Free'" class="subText m-0 mt-3"
                        >/free forever</span
                      >
                      <span v-else class="subText m-0 mt-3">/Monthly</span>
                    </div>

                    <div
                      class="title d-flex flex-column justify-content-between align-items-start"
                    >
                      <p class="m-0">{{ plan.name }} <span
                        v-if="plan.name == 'Pro'"
                        class="mb-2  most-popular "
                        >Most popular</span
                      ></p>
                     
                    </div>

                    <div class="priceText" style="display:none;">
                      <span>{{ plan.description }}</span>
                    </div>
                  </div>
                </div>

                <div class="buttonSection">
                <button
                  :disabled="isBtnDisabled(plan)"
                  class="btn w-100 px-3 py-2 StartBtn"
                  :style="getBtnStyles(plan)"
                  @click="openPlan(plan)"
                >
                  <span class="startBtn">
                    {{
                      user.planId == plan.id ? "Current Plan" : "Start Now"
                    }}</span
                  >
                </button>
              </div>

                <div class="midSection p-3">
                  <div class="midFeatures mb-2">
                    <!-- Engagements -->
                    <div class="">
                      <div>
                        <p class="m-0 mb-2">
                          <i
                            class="fa fa-check-circle mr-2"
                            :style="getTextColor(plan)"
                            aria-hidden="true"
                          ></i>
                          <span>{{ plan.plan_quota.engagements }} </span>
                          Engagements monthly
                        </p>
                      </div>
                    </div>
                    <!-- Engagements End-->

                    <div class="">
                      <p class="m-0 mb-2">
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        <span class="" v-if="plan.plan_quota.quizzes <= 1"
                          >{{ plan.plan_quota.quizzes }} Quiz</span
                        >
                        <span class="" v-else
                          >{{ plan.plan_quota.quizzes }} Quizzes</span
                        >
                      </p>
                    </div>
                    <!-- Quiz End-->
                    <div class="">
                      <p
                        class="m-0 mb-2"
                        v-if="plan.plan_quota.questions !== 0"
                      >
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        Up to
                        <span class=""> {{ plan.plan_quota.questions }} </span
                        >Questions
                      </p>
                      <p
                        v-else
                        class="font-weight-bold text-uppercase m-0 mb-2"
                      >
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        Unlimited
                      </p>
                    </div>
                    <!-- Question End-->
                    <div class="">
                      <p class="m-0 mb-2" v-if="plan.plan_quota.products !== 0">
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        Up to
                        <span>{{ plan.plan_quota.products }} </span>Products
                      </p>
                      <p
                        v-else
                        class="font-weight-bold text-uppercase m-0 mb-2"
                      >
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        Unlimited
                      </p>
                    </div>
                    <!-- Products End-->
                  </div>

                  <div class="midCheckFeatures">
                    <div v-for="(check, index) in planFeature" :key="index">
                      <p class="m-0 mb-2">
                        <i
                          class="fa fa-check-circle mr-2"
                          :style="getTextColor(plan)"
                          aria-hidden="true"
                        ></i>
                        {{ check.text }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="endSection px-3 pb-3">
                  <!-- <div class="d-flex align-items-center" v-for="(service , index) in plan.services" :key="index">
                               <p class="text-uppercase mr-2" style="font-weight:800;" :style="getTextColor(plan)">{{service.title}}</p>
                           </div> -->
                  <!-- <div class="d-flex align-items-center">
                               <p class="text-uppercase mr-2" :style="`font-weight:900;color:${getTextColor(plan)}`">{{plan.AnaliticsText}}</p>
                               <p>{{plan.AnaliticsTextValue}}</p>
                           </div> -->
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <!-- Monthly Plan ends -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      selectedBtn: "yearly",
      planFeature: [
        {
          text: "Integrations",
        },
        {
          text: "Jump Logics",
        },
      ],
      plans: [
        {
          type: "free",
          id: 1,
          title: "Free",
          price: "0",
          priceSubText: "free forever",
          priceText:
            "All the features you need to test out your quiz. Try now for free",
          isSelected: true,
          midSectionTitle: "Free Plan Include:",
          totalEngagements: 100,
          totalQuiz: 1,
          totalQuestions: 10,
          totalProducts: 15,
          TemplateText: "FULL",
          TemplateValue: "Templates",
          AnaliticsText: "Basic",
          AnaliticsTextValue: "Analitics",
          planfeature: [
            {
              text: "Integrations",
            },
            {
              text: "Jump Logics",
            },
          ],
        },
        {
          type: "basic",
          id: 4,
          title: "Basic",
          price: "15",
          priceSubText: "Monthly",
          priceText:
            " All the features you need to increase a small shop's sales",
          isSelected: false,
          midSectionTitle: "Basic Plan Include:",
          totalEngagements: 500,
          totalQuiz: 1,
          totalQuestions: 15,
          totalProducts: 100,
          TemplateText: "FULL",
          TemplateValue: "Templates",
          AnaliticsText: "Basic",
          AnaliticsTextValue: "Analitics",
          planfeature: [
            {
              text: "Integrations",
            },
            {
              text: "Jump Logics",
            },
          ],
        },
        {
          type: "pro",
          id: 5,
          title: "Pro",
          price: "49",
          priceSubText: "Monthly",
          priceText:
            "Go pro. All the features you need to increase your shop's     sales",
          isSelected: false,
          midSectionTitle: "Pro Plan Include:",
          totalEngagements: 1000,
          totalQuiz: 4,
          totalQuestions: "UNLIMITED",
          totalProducts: "UNLIMITED",
          TemplateText: "Limited",
          TemplateValue: "Templates",
          AnaliticsText: "Basic",
          AnaliticsTextValue: "Analitics",
          planfeature: [
            {
              text: "Integrations",
            },
            {
              text: "Jump Logics",
            },
          ],
        },
        {
          type: "enterprise",
          id: 6,
          title: "Enterprise",
          price: "199",
          priceSubText: "Monthly",
          priceText:
            "All the features you need to increase a big enterprise's sales",
          isSelected: false,
          midSectionTitle: "Enterprise Plan Include:",
          totalEngagements: 2000,
          totalQuiz: 10,
          totalQuestions: "UNLIMITED",
          totalProducts: "UNLIMITED",
          TemplateText: "Limited",
          TemplateValue: "Templates",
          AnaliticsText: "Basic",
          AnaliticsTextValue: "Analitics",
          planfeature: [
            {
              text: "Integrations",
            },
            {
              text: "Jump Logics",
            },
          ],
        },
      ],

      monthlyPlans: [],
      yearlyPlans: [],
      user: {},
    };
  },
  methods: {
    async getUser() {
      const response = await axios.get(`/getCurrentUser`);

      if (response.status == 200 && response.data.status == "fail") {
        this.user = {};
      } else {
        this.user = response.data.data;
      }
    },
    changeSelectedBtn(item) {
      this.selectedBtn = item;
    },

    getTextColor(plan) {
      let style = ` color:${plan.color};`;
      return style;
    },
    async getPlans() {
      try {
        let data = {
          plans: true,
        };
        const response = await axios.get(`/loadLandingPageData`, {
          params: data,
        });
        if (response.status == 200 && response.data.status == "success") {
          this.monthlyPlans = response.data.data.plans.monthly;
          let freePlan = response.data.data.plans.monthly[0];
          this.yearlyPlans = [freePlan, ...response.data.data.plans.yearly];
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
    openPlan(plan) {
      
      // if (plan) window.location.href = `/choosePlan/${plan.id}`;
         const user_access_token = localStorage.getItem("user_access_token")
          const api_Url = process.env.VUE_APP_API_BASE_URL
        if(user_access_token && plan.id){
          const url = `${api_Url}purchase/plan?planId=${plan.id}&token=${user_access_token}`
          window.open(url , '_self')
        }
   
    },
    getBtnStyles(plan) {
      let style = `mix-blend-mode: normal;
border-radius: 24px;background:${plan.bg_color};color:#ffffff;opacity:0.8;`;

      return style;
    },
    isBtnDisabled(plan) {
      if (Object.entries(this.user).length !== 0) {
        if (plan.id == this.user.plan_id) return true;
        return false;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getUser();
    this.getPlans();
  },
};
</script>

<style scoped>
.ChoosePlanNew {
  height: 100%;
  /* background-image: url("/assets/img/new-website/home-main-hero2.svg"); */
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
}
.ChoosePlanNew .MainSection {
  padding: 1% 5%;
}
.MainSection .TitleDiv {
  padding: 48px;
}

.Choose-plan-Main {
  padding: 2% 5%;
}
.MainSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 136%;

  text-align: center;

  color: #161e34;
}
.Error-Btn {
  background: #4d1b7e;
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.Error-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}

.Choose-plan-Main .PricingCard {
  background: #ffffff;
  box-shadow: 0px -2px 16px rgba(77, 27, 126, 0.1);
  border-radius: 20px;
}
.Choose-plan-Main .PricingDiv .PriceTopSection {
  color: #ffffff;
  font-family: "Poppins";
}
.Choose-plan-Main .PriceTopSection .priceSection .title p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #1f1f28;
}

.Choose-plan-Main .priceSection .price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;

  color: #1f1f28;
}
.Choose-plan-Main .priceSection .subText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #7c8290;
  width: auto;
}

.Choose-plan-Main .priceSection .priceText span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #7c8290;
}

.Choose-plan-Main .buttonSection a {
  background: transparent;
  border: 1.02182px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 5.44969px 16.3491px -2.72485px rgba(141, 49, 192, 0.3);
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}
.Choose-plan-Main .buttonSection a:hover {
  background: #ffffff;
  color: #042542;
}

.Choose-plan-Main .midSection {
  font-family: "Poppins";
  color: #535355;
}
.Choose-plan-Main .midTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.Choose-plan-Main .midFeatures p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7c8290;
}
.Choose-plan-Main .midFeatures p span {
  font-weight: 700;
}
.Choose-plan-Main .midCheckFeatures p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7c8290;
}

.Choose-plan-Main .endSection p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f1f28;
}

.Choose-plan-Main .startBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #ffffff;
}

.Pricing-Comp-Main .buttonSection .StartBtn:hover {
  opacity: 1 !important;
}

.Choose-plan-Main .most-popular {
  background: #0FD452;
  border-radius: 5.70473px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
   padding: 8px 4px;
  color: #ffffff;
}

.Choose-plan-Main .btn-group {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 16px rgba(77, 27, 126, 0.1);
  border-radius: 100px;
  color: #4d1b7e;
}
.Choose-plan-Main .btn-group p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  cursor: pointer;
  text-align: center;

  color: #4d1b7e;
}
.Choose-plan-Main .btn-group:hover {
  background: #4d1b7e0d;
}
.Choose-plan-Main .btn-group p:hover {
  background: #4d1b7e0d;
  /* opacity: 0.05; */
  border-radius: 0px 100px 100px 0px;
}
.Choose-plan-Main .btn-group .yearly:hover {
  background: #4d1b7e0d;
  /* opacity: 0.05; */
  border-radius: 100px 0px 0px 100px;
}

.Choose-plan-Main .btn-group .selectedBtn {
  background: #4d1b7e;
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;

  border-radius: 100px;
}
.Choose-plan-Main .btn-group .selectedBtn:hover {
  color: #ffffff;
}
.Choose-plan-Main .topSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 136%;
  text-align: center;
  color: #1f1f28;
}
.Choose-plan-Main .topSection .subText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 136%;
  text-align: center;
  color: #7c8290;
  padding: 0 25%;
}

@media (max-width: 1057px) {
  .Choose-plan-Main .topSection .subText {
    padding: 0 10%;
  }
}


</style>
